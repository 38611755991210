import urls from 'urls';
import * as actions from './constants';
import {UPDATE_AVATARS, selectAvatar} from './avatars';
import {LIKED_POST, UNLIKED_POST, POST_REPLY, EDIT_POST, DELETE_POST} from './posts';
import {SET_NEW_MESSAGES} from './newMessages';
import {updateGuestId, updateUserId, updateRsvp} from './global';
import {apiFetcher} from './apiFetcher';
import {trackInviteRSVP, trackSubmitedRSVP} from '~/ViewInvitation/utils/analytics';

export function fetchSinglePhotoPost(eventId, postId, callback) {
  const eId = eventId || window.event_id;
  const url = urls.getAPI('api_single_photo_post', {eventId: eId, postId});
  return apiFetcher(url, null, [actions.FETCH_SINGLE_PHOTO_POST, callback], null, {postId});
}

export function fetchMorePhotos(eventId, cursor, gid, callback) {
  const eId = eventId || window.event_id;
  const gId = gid || window.guest_id || '';
  const url = urls.getAPI('api_all_photos', {eventId: eId, cursor, gid: gId});
  return apiFetcher(url, null, [actions.FETCH_PHOTOS, callback], null);
}

export function initInvitationSummary(store) {
  if (window.summary) {
    // If we already have the data, then use it
    store.dispatch(async (dispatch) => {
      dispatch({type: actions.UPDATE_INVITATION_SUMMARY, results: window.summary});
    });
  } else {
    // If we don't have the data, make a func for the data getter to call when it has it
    window.summaryCallback = () => {
      store.dispatch(async (dispatch) => {
        dispatch({type: actions.UPDATE_INVITATION_SUMMARY, results: window.summary});
      });
    };
  }
}

export function fetchInvitationSummary(eventId) {
  const eId = eventId || window.event_id;
  let url = urls.get('api_invitation_summary', {event_id: eId});
  const variation = evite.experiments.getOrSetVariation('pandemic-summary');
  if (variation === 'parallel') fetch(urls.get('pandemic_invitation_summary', {event_id: eId}));
  else if (variation === 'appengine')
    fetch(urls.get('pandemic_appengine_invitation_summary', {event_id: eId}));
  else if (variation === 'appenginelive')
    url = urls.get('pandemic_appengine_invitation_summary', {event_id: eId});
  return apiFetcher(
    url,
    actions.FETCH_INVITATION_SUMMARY_STARTED,
    actions.UPDATE_INVITATION_SUMMARY,
    actions.FETCH_INVITATION_SUMMARY_FAILED
  );
}

export function fetchAvatarsByUserId(eventId, guestId, userIds) {
  const eId = eventId || window.event_id;
  const gId = guestId || window.guest_id;

  const url = urls.get('ajax_event_avatars', {
    event_id: eId,
    gid: gId,
    user_ids: userIds,
  });
  return apiFetcher(url, null, UPDATE_AVATARS, null);
}

export function fetchGuestlist(eventId, successCallback, failureCallback) {
  const eId = eventId || window.event_id;

  const url = urls.getAPI('api_who_is_coming', {event_id: eId});
  return apiFetcher(url, null, [actions.UPDATE_GUESTLIST, successCallback], failureCallback);
}

export function submitRSVP({event_id: eId, payload, successCallback, failureCallback}) {
  const eventId = eId || window.event_id;

  const request = {
    url: urls.get('ajax_view_rsvp', {event_id: eventId}),
    method: 'POST',
    payload: {
      ...payload,
      ...(payload.email ? {email: payload.email.toLowerCase()} : {}),
    },
  };
  const updateGlobals = (dispatch, getState, {results}) => {
    updateGuestId(results.guest.id);
    updateUserId(results.guest.user_id);
    updateRsvp(results.guest.response);
  };
  const updateAvatar = (dispatch, getState, {results}) => {
    // Get the users avatar if we don't already have it
    const state = getState();
    if (selectAvatar(results.guest.user_id)(state)) return;
    dispatch(fetchAvatarsByUserId(eventId, results.guest.id, [results.guest.user_id]));
  };
  // Dual analytics calls for RSVP are probably redundant, but keeping around for now in case
  // some reports are using the old version.
  const updateAnalytics1 = (dispatch, getState, {results}) => {
    trackSubmitedRSVP(results.guest.response);
  };
  const updateAnalytics2 = (dispatch, getState, {results}) => {
    trackInviteRSVP(results.guest.response, results.guest.optin);
  };
  return apiFetcher(
    request,
    null,
    [
      updateAnalytics1,
      actions.SUBMIT_RSVP,
      successCallback,
      updateGlobals,
      updateAnalytics2,
      updateAvatar,
      fetchInvitationSummary(eventId),
    ],
    [actions.SUBMIT_RSVP_FAILED, failureCallback],
    null
  );
}

export function likePost(postId) {
  const eventId = window.event_id;
  const userId = window.user_id;
  const guestId = window.guest_id;

  const url = urls.get('api_like_post', {
    postId,
    eventId,
  });

  return apiFetcher(url, null, LIKED_POST, null, {
    postId,
    userId,
    guestId,
  });
}

export function unlikePost(postId) {
  const eventId = window.event_id;
  const userId = window.user_id;
  const guestId = window.guest_id;

  const url = urls.get('api_unlike_post', {
    postId,
    eventId,
  });

  return apiFetcher(url, null, UNLIKED_POST, null, {
    postId,
    userId,
    guestId,
  });
}

export function postReply(postId, comment) {
  const eventId = window.event_id;

  const url = urls.getAPI('api_comment', {
    postId,
    eventId,
    comment,
  });

  return apiFetcher(url, null, POST_REPLY, null, {postId, eventId, comment});
}

export function editPostComment(postId, comment) {
  const eventId = window.event_id;

  const url = urls.getAPI('api_edit_post_comment', {
    postId,
    eventId,
    comment,
  });

  return apiFetcher(url, null, EDIT_POST, null, {
    postId,
  });
}

export function deletePost(postId) {
  const eventId = window.event_id;

  const url = urls.getAPI('api_delete_post', {
    postId,
    eventId,
  });

  return apiFetcher(url, null, DELETE_POST, null, {postId, eventId});
}

export function updateGuestNotificationSettings(settings, successCallback, failureCallback) {
  const eventId = window.event_id;
  const guestId = window.guest_id;

  const url = urls.getAPI('api_patch_guest_notifications', {
    eventId,
    guestId,
    payload: {
      notifications: {
        ...settings,
      },
    },
  });

  return apiFetcher(
    url,
    null,
    [actions.GUEST_NOTIFICATIONS_UPDATED, successCallback],
    failureCallback
  );
}

export function refreshFundraiser() {
  const eventId = window.event_id;

  const url = urls.getAPI('api_donation_refresh', {
    eventId,
    payload: {refresh: true},
  });

  return apiFetcher(url);
}

export function voteForPollOption(
  pollId,
  pollAction,
  payload,
  successCallback,
  failureCallback = null
) {
  const eventId = window.event_id;

  const onFail =
    failureCallback ||
    ((e) => {
      evite.error('unable to record poll vote', e);
    });

  const url = urls.getAPI('api_vote_poll_option', {
    eventId,
    pollId,
    pollAction,
    payload,
  });

  return apiFetcher(url, null, [actions.POLL_VOTE_UPDATED, successCallback], onFail);
}

export function checkForNewMessages() {
  // TODO: Extend apiFetcher to support headers so we can simplify this function
  if (!window.guest_id) return () => {};
  const url = `/tsunami/v1/services/event/${window.event_id}/guest/${window.guest_id}/messages/status`;
  const getMessages = {
    method: 'GET',
    credentials: 'include',
    cache: 'no-cache',
    headers: {
      'X-CSRFToken': evite.cookie('csrftoken'),
      Accept: 'application/json',
    },
  };
  return async (dispatch) => {
    try {
      const response = await evite.fetch(url, getMessages);
      if (response.ok) {
        const responseText = await response.json();
        dispatch({
          type: SET_NEW_MESSAGES,
          responseText,
        });
      } else {
        // the error will only emit the blue dot, so let's just console error
        evite.error('error fetching new message status', response);
      }
    } catch (e) {
      evite.error('error fetching new message status', e);
    }
  };
}

export function fetchWhatToBring(eventId) {
  const eId = eventId || window.event_id;
  const url = urls.getAPI('api_wtb', {eventId: eId});
  return apiFetcher(url, null, actions.FETCH_WTB, null);
}

export function removeOtherWhatToBringItem(eventId, wtbListId, itemId) {
  const eId = eventId || window.event_id;
  const url = urls.getAPI('api_wtb_remove_other', {eventId: eId, wtbListId, itemId});
  return apiFetcher(url, null, actions.WTB_REMOVE_OTHER, null, {
    wtbListId,
  });
}

export function postWhatToBringSignup(
  eventId,
  wtbListId,
  itemId,
  quantity,
  {userId, userName, itemName = ''}
) {
  const eId = eventId || window.event_id;
  const uId = userId || window.user_id;
  const uName = userName || '';
  const url = urls.getAPI('api_wtb_signup', {
    eventId: eId,
    wtbListId,
    itemId,
    quantity,
    itemName,
  });
  return apiFetcher(url, null, actions.WTB_UPDATED, null, {
    userId: uId,
    userName: uName,
    itemId,
    itemName,
  });
}

export function removeUserSignUp(eventId, wtbListId, itemId) {
  const eId = eventId || window.event_id;
  const userId = window.user_id || '';
  const url = urls.getAPI('api_wtb_remove_user_signup', {
    eventId: eId,
    wtbListId,
    itemId,
  });

  return apiFetcher(url, null, actions.WTB_REMOVE_USER_SIGNUP, null, {
    eventId: eId,
    wtbListId,
    itemId,
    userId,
  });
}
