import urls from 'urls';
import {Button} from '~/common/_pb_components/atoms/Button';
import {Link} from '~/common/_pb_components/atoms/Link';
import {webviewOpenEventOverview} from '~/ViewInvitation/webview/webview';

export const ManageEventCTA = ({renderForWebview = false, eventId, ctaWordings}) => {
  if (renderForWebview) {
    // Webview; render as button with JS bridge function as onClick
    return (
      <Button
        variant="primary"
        size="large"
        fullWidth
        data-qa-id="invitation-manage"
        className="view-invitation__manage-invitation-link"
        onClick={() => webviewOpenEventOverview({eventId})}
      >
        Manage {ctaWordings}
      </Button>
    );
  }

  // Not webview; render as link with event overview URL as href
  return (
    <Link
      variant="large"
      as="primary"
      fullWidth
      data-qa-id="invitation-manage"
      href={urls.get('event_dashboard', {event_id: eventId})}
      className="view-invitation__manage-invitation-link"
    >
      Manage {ctaWordings}
    </Link>
  );
};
