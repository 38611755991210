/* eslint-disable max-lines-per-function */
/* eslint-disable no-nested-ternary */
import {useState, useEffect, useRef} from 'react';
import {useSelector, useStore} from 'react-redux';
import {useLocation} from 'react-router-dom';
import {useMatchQuery} from '~/common/hooks/useMatchQuery';
import {getUserData} from '~/common/auth/utils';
import {getUsedAsTypeName, isCanceled} from '~/common/utils/event_utils';
import {toastAlert} from '~/common/_pb_components/atoms/Toast';
import {Typography} from '~/common/_pb_components/atoms/Typography';
import {PB_AlertLine as AlertLineIcon} from '~/common/svg/PB_AlertLine';
import {PB_CheckmarkCircle as CheckmarkCircleIcon} from '~/common/svg/PB_CheckmarkCircle';
import {PB_CloseCircle as CloseCircleIcon} from '~/common/svg/PB_CloseCircle';
import {PB_QuestionCircle as QuestionCircleIcon} from '~/common/svg/PB_QuestionCircle';
import {GuestRsvpActions} from './GuestRsvpActions';
import {submitRSVP} from '~/ViewInvitation/reducers/apiActions';
import {selectEventHasPassed} from '~/ViewInvitation/reducers/eventDetails';
import {MoreDetailsIndicator} from '~/ViewInvitation/routes/Landing/MoreDetailsIndicator';
import {RSVPModal} from './RSVPModal';
import {GuestReaction} from '~/GuestReaction/GuestReaction';
import {webviewSendRsvpDetails} from '~/ViewInvitation/webview/webview';
import {
  isFabricWebview as isFabricWebviewCheck,
  isLegacyFabricWebview as isLegacyFabricWebviewCheck,
} from '~/common/utils/webview';
import {ManageEventCTA} from './ManageEventCTA';
import {
  largishAndUp,
  emerald,
  feedbackNo,
  feedbackMaybe,
} from '~sass/pb_styleguide/base/_exports.sass';

const isLegacyFabricWebview = isLegacyFabricWebviewCheck(); // true if page is in any webview (old or new)
const fabricWebviewHasEventCtaBridges = isFabricWebviewCheck(['viewInvitationOpenEventOverview']); // true only for new app webview
const shouldShowRsvpPanel = !isLegacyFabricWebview || fabricWebviewHasEventCtaBridges; // show RSVP panel if NOT webview, or if webview has RSVP bridge function

export const StickyEditEvent = ({forceShow}) => {
  const location = useLocation();
  const store = useStore();
  const {matches: largeScreens} = useMatchQuery(`(min-width: ${largishAndUp})`);

  const hostIds = window.evite_event.host_ids || [];
  const pendingHostIds = window.evite_event.pending_host_ids || [];
  const isCohost = hostIds.includes(window.user_id) && !pendingHostIds.includes(window.user_id);
  const isHostOrCohost = window.user_id === window.evite_event.host_id || isCohost;

  const rsvpOff = useSelector((state) => state.eventDetails.rsvpOff);
  const {isWebview} = window;

  const searchParams = new URLSearchParams(location.search);
  const gid = searchParams.get('gid');

  const eventId = useSelector((state) => state.eventDetails?.eventId ?? null);
  const userData = getUserData(isWebview);
  const {name, email} = useSelector((state) => state.user);
  const guestId = userData.guest_id || userData.guestId || gid || window.guest_id;

  if (!userData.userName) userData.userName = name;
  if (!userData.email) userData.email = email;
  const hasGuestReacted = window.evite_event?.rsvp?.comments;

  const [hideGuestReactions, setHideGuestReactions] = useState(false);

  const isPremium = useSelector(
    (state) => state.eventDetails?.isPremium || state.eviteEvent.isPremium
  );
  const origin = useSelector((state) => state.eventDetails?.origin || state.eviteEvent.origin);
  const eventStatus = useSelector((state) => state.eventDetails?.status || state.eviteEvent.status);
  const usedAs = useSelector((state) => state.eventDetails?.usedAs || state.eviteEvent?.used_as);
  const rsvpResponse = useSelector((state) => state.rsvp?.rsvpResponse);
  const hasRsvpd = useSelector((state) => state.rsvp?.hasRsvpd);
  const ctaWordings = getUsedAsTypeName(usedAs).toLowerCase();
  const loadedDefaultRsvp = useRef(false);

  const [updatingRsvp, setUpdatingRsvp] = useState(false);
  const [wipRsvpStatus, setWipRsvpStatus] = useState();
  const urlParams = new URLSearchParams(window.location.search);
  const showRsvpOverlayOnLoad = urlParams.get('rsvp') === '1';
  const [rsvpOverlayOpen, setRsvpOverlayOpen] = useState(showRsvpOverlayOnLoad);

  const eventHasPassed = useSelector(selectEventHasPassed);
  const eventIsCanceled = isCanceled(eventStatus);
  const [hideMore, setHideMore] = useState(false);

  const [atTop, setAtTop] = useState(true);
  const toggleShowRSVP = () => {
    setAtTop(window.scrollY === 0);
    setHideMore(window.scrollY > 100);
  };

  useEffect(() => {
    document.addEventListener('scroll', toggleShowRSVP);

    if (window.location.href.includes('/messages')) {
      setHideGuestReactions(true);
    }

    return () => {
      document.removeEventListener('scroll', toggleShowRSVP);
    };
  }, []);

  const rsvpDrawer = useRef(null);

  useEffect(() => {
    const footer = document.getElementById('footer');
    if (!footer) return;
    if (largeScreens) {
      footer.style.paddingBottom = '5rem';
    } else {
      footer.style.paddingBottom = '11rem';
    }
  }, [largeScreens]);

  useEffect(() => {
    if (!loadedDefaultRsvp.current) {
      const params = new URLSearchParams(location.search);
      const status = params.get('rsvp_response');
      if (status) {
        setWipRsvpStatus(`${status[0].toUpperCase()}${status.substring(1)}`);
        setRsvpOverlayOpen(true);
      }
    }
  }, [location]);

  const responseMap = {
    yes: "Yes, you're going",
    maybe: "You RSVP'd Maybe",
    no: "You RSVP'd No",
  };

  const onSubmitRSVP = (rsvpGuestId, payload) =>
    new Promise((resolve, reject) => {
      if (!payload.name) {
        toastAlert('Name is required', {
          type: 'error',
          icon: <AlertLineIcon size={0.75} />,
        });
        reject();
      } else if (!payload.email) {
        toastAlert('Email address is required', {
          type: 'error',
          icon: <AlertLineIcon size={0.75} />,
        });
        reject();
      } else {
        store
          .dispatch(
            submitRSVP({
              event_id: eventId,
              guest_id: rsvpGuestId,
              payload,
              successCallback: () => {
                webviewSendRsvpDetails(payload);
                setRsvpOverlayOpen(false);
                resolve();
              },
              failureCallback: async (dispatch, getState, results) => {
                let message = results?.results?.message;
                if (!message) {
                  try {
                    const request = results?.response || results;
                    if (request instanceof Response) {
                      const json = await results?.response?.json();
                      message = json?.message;
                    } else {
                      evite.error(`Failed to find request in results: ${eventId}`);
                      message = 'Unable to RSVP, please contact support';
                    }
                  } catch (e) {
                    evite.error('Failed to find error message', e);
                    message = 'Unable to RSVP, please contact support';
                  }
                }
                toastAlert(message, {
                  type: 'error',
                  icon: <AlertLineIcon size={0.75} />,
                });
                reject(message);
              },
            })
          )
          .then(resolve)
          .catch(reject);
      }
    });

  const showReaction =
    !hideGuestReactions && rsvpOff && isWebview === undefined && !hasGuestReacted && !hasRsvpd;
  const showStickyBar =
    showReaction || // Show if there is a reaction element to show
    isHostOrCohost || // or show if the user is the host or a cohost
    (!isHostOrCohost && !rsvpOff); // or show if the user is NOT the host or cohost and RSVP is not off

  const shouldRenderElement =
    largeScreens || // Show element if desktop
    (showStickyBar && // or: (if showStickyBar (see above) is true
      // and if it's either a non-host/cohost guest with the rsvp panel visible or it's a host/cohost that isn't updating their RSVP status)
      !isHostOrCohost &&
      shouldShowRsvpPanel) ||
    (isHostOrCohost && !updatingRsvp);

  return (
    <>
      {/* If ANY of the conditions in the div are true, render the div, otherwise don't render an empty div */}
      {shouldRenderElement ? (
        <div className={`view-invitation__sticky-wrapper ${!atTop || forceShow ? 'open' : ''}`}>
          {largeScreens && (
            <div className={`more-details--sticky-bar${hideMore ? ' hide' : ''}`}>
              <MoreDetailsIndicator preview={false} />
            </div>
          )}
          {showStickyBar && (
            <div
              className={`view-invitation__sticky-ctas
                          ${!atTop || forceShow ? 'open' : ''}
                          ${!rsvpOff && (!hasRsvpd || updatingRsvp) ? 'show-rsvp-ctas' : ''}`}
              id="viewInvitationCTAWrapper"
              ref={rsvpDrawer}
            >
              {!isHostOrCohost &&
                shouldShowRsvpPanel &&
                (showReaction ? (
                  <GuestReaction
                    rsvpDrawer={rsvpDrawer}
                    eventId={eventId}
                    guestId={guestId}
                    eviteType="event"
                  />
                ) : (
                  <div className="view-invitation__rsvp-wrapper">
                    {eventHasPassed ? (
                      <Typography variant="paragraph3" data-qa-id="event-already-occurred">
                        This event has already occurred.
                      </Typography>
                    ) : eventIsCanceled ? (
                      <Typography variant="paragraph3" data-qa-id="event-canceled">
                        This event has been canceled.
                      </Typography>
                    ) : !hasRsvpd || updatingRsvp ? (
                      <GuestRsvpActions
                        hasRsvpd={hasRsvpd}
                        setUpdatingRsvp={setUpdatingRsvp}
                        setWipRsvpStatus={setWipRsvpStatus}
                        setRsvpOverlayOpen={setRsvpOverlayOpen}
                        isPremium={isPremium}
                        origin={origin}
                      />
                    ) : (
                      <div
                        className="view-invitation__rsvp-details"
                        style={{
                          marginBottom:
                            !isHostOrCohost && hasRsvpd && !updatingRsvp && !largeScreens
                              ? '1rem'
                              : undefined,
                        }}
                      >
                        <div
                          className="view-invitation__rsvp-status-wrapper"
                          data-qa-value={rsvpResponse}
                        >
                          {rsvpResponse === 'yes' ? (
                            <CheckmarkCircleIcon ratio={0.75} color={emerald} />
                          ) : rsvpResponse === 'no' ? (
                            <CloseCircleIcon ratio={0.75} color={feedbackNo} />
                          ) : (
                            rsvpResponse === 'maybe' && (
                              <QuestionCircleIcon ratio={0.75} color={feedbackMaybe} />
                            )
                          )}
                          <Typography
                            variant="paragraph2"
                            classNames="view-invitation__rsvp-status"
                          >
                            {responseMap[rsvpResponse]}
                          </Typography>
                        </div>
                        <button
                          className="unstyled-button link"
                          data-qa-id="invitation-change-rsvp"
                          onClick={() => setUpdatingRsvp(true)}
                          type="button"
                        >
                          Change RSVP
                        </button>
                      </div>
                    )}
                  </div>
                ))}
              {isHostOrCohost && !updatingRsvp ? (
                <ManageEventCTA
                  renderForWebview={fabricWebviewHasEventCtaBridges}
                  eventId={eventId}
                  ctaWordings={ctaWordings}
                />
              ) : null}
            </div>
          )}
        </div>
      ) : null}
      {rsvpOverlayOpen && <div className="active-overlay-bg" />}
      <RSVPModal
        status={wipRsvpStatus}
        onClose={() => setRsvpOverlayOpen(false)}
        open={rsvpOverlayOpen}
        onSubmitRSVP={onSubmitRSVP}
      />
    </>
  );
};
